import React, { Fragment } from "react"
import styled from "styled-components"
import { Title, Subtitle } from "../components/base/Text"
import { FluidContainer, Background } from "../components/base/Container"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { optionsActivities } from "./formatter"
import {
  CodingIcon,
  SurfingIcon,
  SightseeingIcon,
  TripIcon,
  DrinksIcon,
  YogaIcon,
  SunnyIcon,
  PartlyCloudyIcon,
  CloudyIcon,
} from "../components/base/Icons"
import PoweredBy from "./PoweredBy"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.colors.grey150};
  padding: ${props => props.theme.margins.xxl} 0;
  @media (max-width: ${p => p.theme.screen.medium}) {
    padding: ${props => props.theme.margins.xl} 0;
  }
`

const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 calc(33.33% - 1.333rem);
  max-width: calc(33.33% - 1.333rem);
  margin-right: ${props => props.theme.outerMargin};
  margin-bottom: ${props => props.theme.outerMargin};
  box-shadow: ${props => props.theme.shadows.lg};
  /* background: ${props => props.theme.colors[props.color]}; */
  &:nth-child(3n) {
    margin-right: 0;
  }
  @media (max-width: ${p => p.theme.screen.medium}) {
    flex: 1 1 calc(50% - 1rem);
    max-width: calc(50% - 1rem);
    margin-right: ${props => props.theme.outerMargin};
    &:nth-child(3n) {
      margin-right: ${props => props.theme.outerMargin};
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  @media (max-width: ${p => p.theme.screen.mobile}) {
    flex: 1 1 100%;
    max-width: 100%;
    margin-right: 0;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding: ${props => props.theme.margins.xl};
  background: ${props => props.theme.colors.white};
`

const ProgramContainer = styled.div`
  margin-bottom: ${props => props.theme.margins.lg};
  ul {
    margin-left: ${props => props.theme.margins.md};
    margin-bottom: ${props => props.theme.margins.md};
    li {
      font-size: ${props => props.theme.fontSizes.nm};
      color: ${props => props.theme.colors.grey800};
    }
  }
`

const Header = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  background: ${props => props.theme.colors[`${props.color}500`]};
  padding: ${props => props.theme.margins.xl};
  @media (max-width: ${p => p.theme.screen.mobile}) {
    padding: ${props => props.theme.margins.lg};
  }
`

const LeftPanel = styled.div``
const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
`

const BarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.margins.sm};
`
const IconContainer = styled.span`
  margin-right: ${props => props.theme.margins.nm};
  svg {
    width: 20px;
    height: 20px;
    stroke: rgba(255, 255, 255, 0.75);
    fill: rgba(0, 0, 0, 0);
  }
`

const Bar = styled.div`
  width: 100px;
  height: 28px;
  background: rgba(255, 255, 255, 0.25);
`

const Ratio = styled.div`
  width: ${props => props.ratio}%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
`

const Estimated = styled.div`
  display: flex;
  align-items: center;
  h3 {
    margin-right: ${props => props.theme.margins.nm};
  }
  svg {
    width: 24px;
    height: 24px;
    stroke: rgba(255, 255, 255, 0.75);
    fill: rgba(255, 255, 255, 0);
  }
`

const Activities = styled.div`
  margin-top: auto;
  padding: ${props => props.theme.margins.lg};
  padding-bottom: 0;
  border-top: 2px solid ${props => props.theme.colors.grey200};
  p {
    color: ${props => props.theme.colors.grey900} !important;
    font-size: ${props => props.theme.fontSizes.nm} !important;
  }
`

const Block = ({ block, color }) => {
  const {
    title,
    trainingRatio,
    surfingRatio,
    sightseeingRatio,
    tripRatio,
    drinksRatio,
    yogaRatio,
    temperature,
    weather,
    topics: { json: topics },
    activities: { json: activities },
  } = block

  const icons = {
    sunny: SunnyIcon,
    partlyCloudy: PartlyCloudyIcon,
    cloudy: CloudyIcon,
  }

  const WeatherIcon = icons[weather]

  return (
    <BlockContainer>
      <Header color={color}>
        <LeftPanel>
          <Title size="lg" color="white" margin="md" black>
            {title}
          </Title>

          <Subtitle size="xs" color="transparent75" margin="xs" uppercase>
            Estimated
          </Subtitle>
          <Estimated>
            <Subtitle
              size="nm"
              color="white"
              bold
            >{`${temperature}°C`}</Subtitle>
            <WeatherIcon />
          </Estimated>
        </LeftPanel>
        <RightPanel>
          {!!trainingRatio && (
            <BarContainer>
              <IconContainer>
                <CodingIcon />
              </IconContainer>
              <Bar>
                <Ratio ratio={trainingRatio} />
              </Bar>
            </BarContainer>
          )}
          {!!surfingRatio && (
            <BarContainer>
              <IconContainer>
                <SurfingIcon />
              </IconContainer>
              <Bar>
                <Ratio ratio={surfingRatio} />
              </Bar>
            </BarContainer>
          )}
          {!!sightseeingRatio && (
            <BarContainer>
              <IconContainer>
                <SightseeingIcon />
              </IconContainer>
              <Bar>
                <Ratio ratio={sightseeingRatio} />
              </Bar>
            </BarContainer>
          )}
          {!!tripRatio && (
            <BarContainer>
              <IconContainer>
                <TripIcon />
              </IconContainer>
              <Bar>
                <Ratio ratio={tripRatio} />
              </Bar>
            </BarContainer>
          )}
          {!!yogaRatio && (
            <BarContainer>
              <IconContainer>
                <YogaIcon />
              </IconContainer>

              <Bar>
                <Ratio ratio={yogaRatio} />
              </Bar>
            </BarContainer>
          )}
          {!!drinksRatio && (
            <BarContainer>
              <IconContainer>
                <DrinksIcon />
              </IconContainer>
              <Bar>
                <Ratio ratio={drinksRatio} />
              </Bar>
            </BarContainer>
          )}
        </RightPanel>
      </Header>

      <ContentContainer>
        <ProgramContainer>
          <Fragment>{documentToReactComponents(topics)}</Fragment>
        </ProgramContainer>

        <Activities>
          {documentToReactComponents(activities, optionsActivities)}
        </Activities>
      </ContentContainer>
    </BlockContainer>
  )
}

const Program = ({
  program,
  color,
  poweredBy,
  poweredByLogo,
  poweredByUrl,
}) => {
  return (
    <Background color="grey150">
      <FluidContainer>
        <Wrapper>
          <Title size="xxl" color={`${color}900`} black>
            Program
          </Title>
          <PoweredBy
            poweredBy={poweredBy}
            poweredByLogo={poweredByLogo}
            poweredByUrl={poweredByUrl}
          />
          <Container>
            {program.blocks.map(block => (
              <Block key={block.title} block={block} color={color} />
            ))}
          </Container>
        </Wrapper>
      </FluidContainer>
    </Background>
  )
}

export default Program
