import React from "react"

export const TeamIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24">
    <g transform="matrix(1,0,0,1,0,0)">
      <path
        d="M 6.542,8.5c0,0.552,0.448,1,1,1h1l3,3v-3h7c0.552,0,1-0.448,1-1v-7c0-0.552-0.448-1-1-1h-11 c-0.552,0-1,0.448-1,1V8.5z "
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 5.042,11.5c1.243,0,2.25,1.007,2.25,2.25S6.285,16,5.042,16s-2.25-1.007-2.25-2.25 S3.799,11.5,5.042,11.5z "
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 8.21,18.27c-1.861-1.755-4.792-1.67-6.548,0.191c-0.541,0.573-0.926,1.275-1.12,2.039h6 "
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 19.042,11.5 c1.243,0,2.25,1.007,2.25,2.25S20.285,16,19.042,16s-2.25-1.007-2.25-2.25S17.799,11.5,19.042,11.5z "
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 15.873,18.27 c1.862-1.755,4.794-1.67,6.549,0.192c0.54,0.573,0.926,1.275,1.12,2.038h-6 "
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 12.042,14.5c1.243,0,2.25,1.007,2.25,2.25 S13.285,19,12.042,19s-2.25-1.007-2.25-2.25S10.799,14.5,12.042,14.5z "
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 16.542,23.5c-0.634-2.485-3.162-3.986-5.647-3.353 c-1.647,0.42-2.933,1.706-3.353,3.353H16.542z "
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 15.542,3l2,2l-2,2 "
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 10.542,3l-2,2l2,2 "
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 14.042,3l-2,4"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export const DeveloperIcon = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g strokeLinecap="round" strokeLinejoin="round">
      <path d="m23.5 9.78c0 .552-.448 1-1 1h-5.5l-3.5 3v-3h-1c-.552 0-1-.448-1-1v-8c0-.552.448-1 1-1h10c.552 0 1 .448 1 1z" />
      <path d="m12 23.5s-2.02-.893-3.432-1.415c-.926-.345-.794-1.907-.258-2.494.94-.921 1.404-2.223 1.259-3.531.155-1.65-1.056-3.113-2.706-3.268-.12-.011-.242-.015-.363-.012-1.656-.045-3.036 1.26-3.081 2.917-.003.121.001.243.012.363-.145 1.308.319 2.61 1.259 3.531.536.587.668 2.149-.258 2.494-1.412.522-3.432 1.415-3.432 1.415" />
      <path d="m14.5 3.28 2 2-2 2" />
      <path d="m17.5 6.78h4" />
    </g>
  </svg>
)

export const TickIcon = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
      <path d="m6.75 9 3.294 4.611c.49.686 1.443.845 2.129.355.106-.076.202-.165.285-.265l10.792-12.952" />
      <path d="m16.783 3.824c-4.925-3.048-11.388-1.526-14.436 3.399s-1.526 11.388 3.399 14.436 11.388 1.526 14.436-3.399c1.846-2.984 2.078-6.693.618-9.883" />
    </g>
  </svg>
)

export const BrainIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    height="24"
    width="24"
  >
    <g transform="matrix(1,0,0,1,0,0)">
      <path
        d="M 19.5,23.25v-6.265c3.794-3.515,4.021-9.44,0.506-13.234c-1.775-1.916-4.269-3.003-6.881-3.001 C5.621,0.75,4.3,7.245,1.5,14.25h3v3c0,1.657,1.343,3,3,3H9v3 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 12.75,9c0.828,0,1.5,0.672,1.5,1.5s-0.672,1.5-1.5,1.5 s-1.5-0.672-1.5-1.5S11.922,9,12.75,9z "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 11.476,4.7l-0.442,1.45c-0.151,0.5-0.664,0.798-1.174,0.681L8.388,6.49 C7.667,6.327,6.95,6.78,6.787,7.502C6.69,7.933,6.812,8.384,7.113,8.708l1.031,1.111c0.357,0.384,0.357,0.978,0,1.362l-1.031,1.111 c-0.504,0.542-0.473,1.389,0.069,1.892c0.324,0.301,0.775,0.423,1.206,0.326l1.472-0.341c0.51-0.117,1.023,0.181,1.174,0.681 l0.442,1.453c0.211,0.704,0.952,1.103,1.656,0.892c0.428-0.128,0.764-0.464,0.892-0.892l0.442-1.453 c0.151-0.5,0.664-0.798,1.174-0.681l1.472,0.341c0.721,0.163,1.438-0.29,1.601-1.012c0.097-0.431-0.025-0.882-0.326-1.206 l-1.031-1.111c-0.357-0.384-0.357-0.978,0-1.362l1.031-1.111c0.504-0.542,0.473-1.389-0.069-1.892 c-0.324-0.301-0.775-0.423-1.206-0.326L15.64,6.831c-0.51,0.117-1.023-0.181-1.174-0.681L14.024,4.7 c-0.211-0.704-0.952-1.103-1.656-0.892C11.94,3.936,11.604,4.272,11.476,4.7z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export const DominicIcon = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
      <path d="m15 1.5c1.243 0 2.25 1.007 2.25 2.25s-1.007 2.25-2.25 2.25-2.25-1.007-2.25-2.25 1.007-2.25 2.25-2.25z" />
      <path d="m4.5 15c2.071 0 3.75 1.679 3.75 3.75s-1.679 3.75-3.75 3.75-3.75-1.679-3.75-3.75 1.679-3.75 3.75-3.75z" />
      <path d="m19.5 15c2.071 0 3.75 1.679 3.75 3.75s-1.679 3.75-3.75 3.75-3.75-1.679-3.75-3.75 1.679-3.75 3.75-3.75z" />
      <path d="m12 20.25c-.828 0-1.5-.672-1.5-1.5.004-2.242-1.245-4.298-3.236-5.328-.735-.383-1.019-1.289-.636-2.024.076-.147.177-.279.296-.393l4.043-3.842c.6-.571 1.55-.547 2.121.054.102.108.188.23.254.362.007.013 1.145 2.171 3.158 2.171.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5c-1.817-.032-3.533-.845-4.709-2.231l-1.452 1.381c2.009 1.708 3.165 4.213 3.161 6.85 0 .828-.672 1.5-1.5 1.5z" />
    </g>
  </svg>
)

export const AlexIcon = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
      <path d="m10.125 9.375c1.45 0 2.625 1.175 2.625 2.625s-1.175 2.625-2.625 2.625-2.625-1.175-2.625-2.625 1.175-2.625 2.625-2.625z" />
      <path d="m2.251 20.25c-.828 0-1.5-.671-1.501-1.499 0-.062.004-.125.012-.187l1.393-11.146c.135-1.058.82-1.965 1.8-2.385l.879-.378c5.664-2.409 12.174-1.686 17.172 1.906.78.565 1.242 1.469 1.244 2.432v9.757c0 .828-.672 1.5-1.5 1.5s-1.5-.672-1.5-1.5v-9.757c-4.144-2.977-9.542-3.577-14.238-1.581l-.879.378-1.395 11.146c-.094.75-.731 1.313-1.487 1.314z" />
      <path d="m16.234 6.959 1.054-2.811" />
      <path d="m1.749 10.666 2.962.494" />
    </g>
  </svg>
)

export const RichardIcon = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
      <path d="m12 .75c3.728 0 6.75 3.022 6.75 6.75s-3.022 6.75-6.75 6.75-6.75-3.022-6.75-6.75 3.022-6.75 6.75-6.75z" />
      <path d="m6.014 10.62c2.068.665 5.337.672 5.986-3.87" />
      <path d="m12 6.75c.649 4.542 3.918 4.535 5.986 3.87" />
      <path d="m2.25 23.25c2.392-5.385 8.697-7.811 14.082-5.418 2.415 1.073 4.345 3.003 5.418 5.418" />
      <path d="m5.291 6.75h13.418" />
      <path d="m12 .75v3" />
      <path d="m15 1.451v2.299" />
      <path d="m9 1.451v2.299" />
    </g>
  </svg>
)

export const KwintenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    height="24"
    width="24"
  >
    <title>
      Exported from Streamline App (https://app.streamlineicons.com)
    </title>
    <g transform="matrix(1,0,0,1,0,0)">
      <path
        d="M 12,0.75c2.899,0,5.25,2.351,5.25,5.25s-2.351,5.25-5.25,5.25S6.75,8.899,6.75,6S9.101,0.75,12,0.75z "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 2.25,23.25c0-5.385,4.365-9.75,9.75-9.75s9.75,4.365,9.75,9.75 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 8.25,14.249V15c0,2.071,1.679,3.75,3.75,3.75 s3.75-1.679,3.75-3.75v-0.751 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 6.804,6.75H21 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 9.75,3.75H7.258"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export const AnthonyIcon = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
      <path d="m12 3.75c2.899 0 5.25 2.351 5.25 5.25s-2.351 5.25-5.25 5.25-5.25-2.351-5.25-5.25 2.351-5.25 5.25-5.25z" />
      <path d="m7.476 6.334c2.519 2.611 6.392 3.389 9.724 1.955" />
      <path d="m8.25 17.249v.751c0 2.071 1.679 3.75 3.75 3.75s3.75-1.679 3.75-3.75v-.751" />
      <path d="m21.279 23.25c-1.659-5.125-7.158-7.934-12.283-6.275-2.978.964-5.312 3.298-6.275 6.275" />
      <path d="m3.75 10.5c0 .414.336.75.75.75h2.25v-4.5h-2.25c-.414 0-.75.336-.75.75z" />
      <path d="m19.5 11.25h-2.25v-4.5h2.25c.414 0 .75.336.75.75v3c0 .414-.336.75-.75.75z" />
      <path d="m18.708 6.75c-.415-3.705-3.755-6.371-7.46-5.956-3.132.351-5.605 2.823-5.956 5.956" />
    </g>
  </svg>
)

export const VictorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    height="24"
    width="24"
  >
    <g transform="matrix(1,0,0,1,0,0)">
      <path
        d="M 8.25,17.226v0.751c0,2.071,1.679,3.75,3.75,3.75s3.75-1.679,3.75-3.75v-0.751 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 8.594,4.982 c-2.206,1.881-2.47,5.195-0.589,7.401s5.195,2.47,7.401,0.589s2.47-5.195,0.589-7.401c-0.18-0.212-0.378-0.409-0.589-0.589 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 14.25,2.977c-1.349,0.001-2.533-0.9-2.893-2.2c-1.622,0.08-2.943,1.334-3.107,2.95c0,1.657,1.679,3,3.75,3s3.75-1.343,3.75-3 c0-0.414-0.336-0.75-0.75-0.75L14.25,2.977z "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 21.279,23.227c-1.659-5.125-7.158-7.934-12.283-6.275 c-2.978,0.964-5.312,3.298-6.275,6.275"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export const CodingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    height="24"
    width="24"
  >
    <title>
      Exported from Streamline App (https://app.streamlineicons.com)
    </title>
    <g transform="matrix(1,0,0,1,0,0)">
      <path
        d="M 2.994,1.5h18c0.828,0,1.5,0.672,1.5,1.5v6c0,0.828-0.672,1.5-1.5,1.5h-18c-0.828,0-1.5-0.672-1.5-1.5V3 C1.494,2.172,2.166,1.5,2.994,1.5z "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 4.494,4.5h1.5 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 4.494,7.5h1.5 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 8.994,4.5h1.5 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 8.994,7.5h6 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 13.494,4.5h1.5 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 17.994,4.5h1.5 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 17.994,7.5h1.5 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 0.744,22.5c0,0-0.015-4.266,1.4-6.738c0.534-0.934,1.527-1.511,2.603-1.512h2.632 c0.415,0,0.751,0.337,0.75,0.752c0,0.035-0.002,0.07-0.007,0.104L7.494,19.5c3.75-2.25,3.75,0,1.5,3 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 23.244,22.5 c0,0,0.015-4.266-1.4-6.738c-0.533-0.933-1.525-1.51-2.6-1.512h-2.635c-0.415,0-0.751,0.337-0.75,0.752 c0,0.035,0.002,0.07,0.007,0.104l0.628,4.394c-3.75-2.25-3.75,0-1.5,3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export const SurfingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    height="24"
    width="24"
  >
    <title>
      Exported from Streamline App (https://app.streamlineicons.com)
    </title>
    <g transform="matrix(1,0,0,1,0,0)">
      <path
        d="M 6.75,0.75c1.45,0,2.625,1.175,2.625,2.625S8.2,6,6.75,6S4.125,4.825,4.125,3.375S5.3,0.75,6.75,0.75z "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 6.75,16.5v5.25 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 11.434,13.979c-0.672-0.456-1.245-1.044-1.684-1.727v9.5c0,0.828-0.672,1.5-1.5,1.5s-1.5-0.672-1.5-1.5 c0,0.828-0.672,1.5-1.5,1.5s-1.5-0.672-1.5-1.5V15c0,0.828-0.672,1.5-1.5,1.5s-1.5-0.672-1.5-1.5v-3c0-2.485,2.015-4.5,4.5-4.5H7.9 c1.279,0.001,2.497,0.547,3.35,1.5 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 3.75,15v-3 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 18.262,1.133c0.281-0.242,0.696-0.242,0.977,0 c2.645,2.384,4.111,5.808,4.011,9.367c-0.061,3.972-0.701,7.913-1.9,11.7c-0.197,0.624-0.776,1.049-1.43,1.049h-2.343 c-0.654,0-1.233-0.425-1.43-1.049c-1.199-3.787-1.839-7.728-1.9-11.7C14.148,6.94,15.616,3.516,18.262,1.133z "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 14.378,13.372 l7.916-7.916 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 15.251,18.874l7.997-7.997"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export const TripIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    height="24"
    width="24"
  >
    <g transform="matrix(1,0,0,1,0,0)">
      <path
        d="M 5.25,20.213v1.5c0,0.828,0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5v-1.5 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 18.75,20.213v1.5 c0,0.828-0.672,1.5-1.5,1.5s-1.5-0.672-1.5-1.5v-1.5 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 20.25,18.713c0,0.828-0.672,1.5-1.5,1.5H5.25c-0.828,0-1.5-0.672-1.5-1.5 v-13.5c0-2.485,2.015-4.5,4.5-4.5h7.5c2.485,0,4.5,2.015,4.5,4.5V18.713z "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 9.75,3.713h4.5 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 3.75,6.713h16.5v7.5H3.75V6.713z "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 6.75,17.213h3 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 14.25,17.213h3 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 3.75,8.213h-1.5c-0.828,0-1.5,0.672-1.5,1.5v4.5 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 20.25,8.213h1.5c0.828,0,1.5,0.672,1.5,1.5v4.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export const DrinksIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    height="24"
    width="24"
  >
    <g transform="matrix(1,0,0,1,0,0)">
      <path
        d="M 17.25,9.75h2.1c2.07-0.079,3.814,1.53,3.9,3.6v1.8c-0.086,2.07-1.83,3.679-3.9,3.6h-2.1 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 17.25,8.256v13.6 c0.012,0.758-0.592,1.382-1.35,1.394c0,0,0,0,0,0H5.1c-0.757-0.013-1.361-0.636-1.35-1.393v-12.4 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 8.25,14.899v4.178 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 12.75,12.81 v6.267 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 19.365,5.1c-0.526-1.82-2.428-2.869-4.248-2.343c-0.338,0.098-0.66,0.247-0.952,0.443 c-0.882-2.054-3.262-3.003-5.316-2.121C7.761,1.547,6.932,2.468,6.581,3.6C5.227,2.275,3.055,2.298,1.729,3.652 S0.427,7.178,1.781,8.504c0.535,0.524,1.228,0.858,1.971,0.952h0.006c0.042,1.861,1.584,3.335,3.445,3.293 c0.062-0.001,0.125-0.005,0.187-0.009c1.144-0.126,2.152-0.811,2.69-1.829c0.228-0.399,0.368-0.842,0.41-1.3 c0.065-0.772,0.713-1.364,1.488-1.359c1.743,0,3.705,0.007,5.278,0c1.17,0.02,2.154-0.875,2.244-2.042 C19.514,5.835,19.469,5.46,19.365,5.1z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export const SightseeingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    height="24"
    width="24"
  >
    <g transform="matrix(1,0,0,1,0,0)">
      <path
        d="M 6.75,23.251H3l-2.25-16.5H13.5l-0.366,2.683 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 6,17.251l2.25-16.5l4.5,0.75 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 11.25,20.251h10.5v1.5 c0,0.828-0.672,1.5-1.5,1.5h-7.5c-0.828,0-1.5-0.672-1.5-1.5V20.251z "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 14.25,12.751h4.5c1.657,0,3,1.343,3,3v1.5h-10.5v-1.5 C11.25,14.094,12.593,12.751,14.25,12.751z "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 11.25,17.251h10.5c0.828,0,1.5,0.672,1.5,1.5l0,0c0,0.828-0.672,1.5-1.5,1.5h-10.5 c-0.828,0-1.5-0.672-1.5-1.5l0,0C9.75,17.923,10.422,17.251,11.25,17.251z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export const YogaIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    height="24"
    width="24"
  >
    <g transform="matrix(1,0,0,1,0,0)">
      <path
        d="M 3.375,9.5C4.825,9.5,6,10.675,6,12.125S4.825,14.75,3.375,14.75S0.75,13.575,0.75,12.125S1.925,9.5,3.375,9.5 z "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 18.03,5.716l3.845,3.466c1.471,1.324,1.805,3.501,0.8,5.206l-1.625,2.747c-0.818,1.319-2.262,2.12-3.814,2.115h-3.985 c-1.706,0.006-3.266-0.959-4.024-2.487l-2.171-4.342c-0.289-0.578-0.176-1.275,0.281-1.732l5.25-5.25 c0.586-0.586,1.536-0.585,2.122,0s0.585,1.536,0,2.122l0,0l-4.485,4.485l1.687,3.375c0.252,0.509,0.773,0.831,1.341,0.829h3.984 c0.516,0.003,0.997-0.262,1.27-0.7l1.518-2.424c0.39-0.625,0.269-1.441-0.286-1.925L17.116,8.9 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 9.138,8.888l2.121,2.122 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 16.5,16.25v3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export const SunnyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    height="24"
    width="24"
  >
    <title>
      Exported from Streamline App (https://app.streamlineicons.com)
    </title>
    <g transform="matrix(1,0,0,1,0,0)">
      <path
        d="M 12,5.25c3.728,0,6.75,3.022,6.75,6.75s-3.022,6.75-6.75,6.75S5.25,15.728,5.25,12S8.272,5.25,12,5.25z "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 12,2.25v-1.5 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 18.894,5.106l1.061-1.061 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 21.75,12h1.5 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 18.894,18.894l1.061,1.061 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 12,21.75v1.5 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 5.106,18.894l-1.061,1.061 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 2.25,12h-1.5 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 5.106,5.106L4.045,4.045"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export const PartlyCloudyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    height="24"
    width="24"
  >
    <g transform="matrix(1,0,0,1,0,0)">
      <path
        d="M 8.25,6V3 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 3.75,10.5h-3 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 11.432,7.318l2.121-2.121 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 5.068,7.318L2.947,5.197 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 4.5,12.984 c-1.359-2.081-0.774-4.869,1.306-6.228s4.869-0.774,6.228,1.306c0.04,0.061,0.078,0.123,0.115,0.186 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 11.25,15 c0,0.251,0.016,0.501,0.047,0.75C11.266,15.501,11.25,15.251,11.25,15c0.001-0.377,0.039-0.753,0.113-1.122 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 17.25,21 c3.314,0.004,6.003-2.679,6.007-5.993S20.578,9.004,17.264,9c-2.884-0.003-5.362,2.045-5.901,4.878 c-1.865-0.894-4.102-0.107-4.996,1.758s-0.107,4.102,1.758,4.996C8.632,20.875,9.188,21.001,9.75,21H17.25z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export const CloudyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    height="24"
    width="24"
  >
    <g transform="matrix(1,0,0,1,0,0)">
      <path
        d="M 11.276,16.184c0,0.251,0.016,0.501,0.047,0.75C11.292,16.685,11.276,16.435,11.276,16.184 c0.002-0.377,0.04-0.752,0.113-1.122 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 17.276,22.184c3.314,0.004,6.003-2.679,6.007-5.993s-2.679-6.003-5.993-6.007 c-2.884-0.003-5.362,2.045-5.901,4.878c-1.865-0.894-4.102-0.107-4.996,1.758c-0.894,1.865-0.107,4.102,1.758,4.996 c0.507,0.243,1.062,0.369,1.625,0.368H17.276z "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 6.026,8.684c0,0.251,0.016,0.501,0.047,0.75C6.042,9.185,6.026,8.935,6.026,8.684 c0.002-0.377,0.04-0.752,0.113-1.122 "
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M 17.835,7.176C17,3.969,13.724,2.046,10.517,2.881C8.275,3.465,6.57,5.287,6.135,7.562 C4.27,6.668,2.033,7.455,1.139,9.32s-0.107,4.102,1.758,4.996c0.507,0.243,1.062,0.369,1.625,0.368"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export const RightArrowIcon = () => (
  <svg
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m5.5.75 10.72 10.72c.293.292.293.767.001 1.059 0 0-.001.001-.001.001l-10.72 10.72"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
)

export const StarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    height="24"
    width="24"
  >
    <title>
      Exported from Streamline App (https://app.streamlineicons.com)
    </title>
    <g transform="matrix(1,0,0,1,0,0)">
      <path
        d="M 12.729,1.2l3.346,6.629l6.44,0.638c0.443,0.037,0.772,0.426,0.736,0.869c-0.016,0.191-0.099,0.37-0.236,0.505 l-5.3,5.253l1.965,7.138c0.115,0.434-0.143,0.879-0.577,0.994c-0.192,0.051-0.396,0.03-0.574-0.059L12,19.934l-6.52,3.229 c-0.401,0.202-0.89,0.04-1.092-0.361c-0.089-0.178-0.11-0.382-0.059-0.574l1.965-7.138L0.99,9.837 C0.674,9.524,0.672,9.014,0.985,8.699C1.12,8.562,1.299,8.479,1.49,8.463l6.44-0.638L11.271,1.2c0.206-0.403,0.7-0.562,1.102-0.356 C12.526,0.923,12.651,1.047,12.729,1.2z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)
