import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Subtitle } from "../components/base/Text"

const PoweredByContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.theme.margins.lg} 0;
`

const ImageContainer = styled.div`
  flex: 0 0 auto;
  max-height: 80px;
  min-width: 20px;
  width: 80px;

  margin-bottom: ${props => props.theme.margins.sm};
`

const PoweredBy = ({ poweredBy, poweredByLogo, poweredByUrl }) => (
  <PoweredByContainer>
    <Subtitle size="xs" color="grey400" margin="lg" uppercase center>
      Powered by
    </Subtitle>

    <a href={poweredByUrl} target="_blank" rel="noopener noreferrer">
      <ImageContainer>
        <Img fluid={poweredByLogo.fluid} />
      </ImageContainer>
    </a>

    {poweredBy && (
      <Subtitle size="sm" color="grey900">
        {poweredBy}
      </Subtitle>
    )}
  </PoweredByContainer>
)

export default PoweredBy
