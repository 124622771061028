import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"

import { Background, FluidContainer } from "../components/base/Container"
import { Title, Subtitle } from "../components/base/Text"
import Markdown from "../components/base/markdown/Markdown"

const Container = styled.div`
  padding: ${props => props.theme.margins.xxl} 0;
  max-width: ${props => props.theme.containers.sm};
`

const FaqContainer = styled.div`
  margin-bottom: ${props => props.theme.margins.lg};
`

const query = graphql`
  query {
    allContentfulFaq {
      edges {
        node {
          position
          question
          answer {
            answer
          }
        }
      }
    }
  }
`

const FAQs = ({ color }) => (
  <StaticQuery
    query={query}
    render={data => {
      const faqs = data.allContentfulFaq.edges
        .map(({ node }) => node)
        .sort((a, b) => a.position - b.position)

      return (
        <Background color="grey200">
          <FluidContainer>
            <Container>
              <Title size="xl" color={`${color}900`} margin="xl" black center>
                What you should know
              </Title>
              {faqs.map(faq => {
                return (
                  <FaqContainer key={faq.question}>
                    <Title size="lg" color={`${color}600`} margin="nm" black>
                      {faq.question}
                    </Title>
                    <Subtitle size="nm" color="grey500">
                      <Markdown text={faq.answer.answer} />
                    </Subtitle>
                  </FaqContainer>
                )
              })}
            </Container>
          </FluidContainer>
        </Background>
      )
    }}
  />
)

export default FAQs
