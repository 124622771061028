import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const Container = styled.div`
  position: relative;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${props => props.theme.colors[`${props.color}500`]};
  mix-blend-mode: multiply;
`

const StudentImage = ({ fluid, color }) => (
  <Container>
    <Img fluid={fluid} />
    <Overlay color={color} />
  </Container>
)

export default StudentImage
