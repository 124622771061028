import styled from "styled-components"

const MarkdownStyle = styled.div`
  display: flex;
  flex-direction: column;
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${props => props.theme.colors.primary900};
    line-height: 1.45;
    margin: 0;
    font-weight: ${props => props.theme.weights.bold};
  }

  h1 {
    font-size: ${props => props.theme.fontSizes.xl};
    margin-bottom: ${props => props.theme.margins.lg};
  }

  h2 {
    font-size: ${props => props.theme.fontSizes.lg};
    margin-bottom: ${props => props.theme.margins.md};
    padding-top: ${props => props.theme.margins.lg};
  }

  h3 {
    font-size: ${props => props.theme.fontSizes.md};
    margin-bottom: ${props => props.theme.margins.nm};
  }

  h4 {
    font-size: ${props => props.theme.fontSizes.nm};
    font-weight: ${props => props.theme.weights.regular};
    margin-bottom: ${props => props.theme.margins.lg};
  }

  h5 {
    font-size: ${props => props.theme.fontSizes.lg};
    font-weight: ${props => props.theme.weights.thin};
    margin-bottom: ${props => props.theme.margins.lg};
  }

  p,
  b,
  strong,
  a,
  ul,
  ol li {
    font-size: ${props => props.theme.fontSizes.nm};
    font-weight: ${props => props.theme.weights.regular};
    line-height: 1.75;
    padding-bottom: ${props => props.theme.margins.md};
    color: ${props => props.theme.colors.primary900};
    margin: 0;
  }

  p {
    padding-bottom: ${props => props.theme.margins.nm};
    color: ${props => props.theme.colors.primary900};
  }

  a {
    line-height: 1.5;
    color: ${props => props.theme.colors.primary500};
    text-decoration: none;
    transition: background 0.15s ease-in-out;
    &:visited {
      color: ${props => props.theme.colors.primary500};
    }
    &:hover {
      text-decoration: underline;
    }
    b {
      font-weight: ${p => p.theme.weights.bold};
    }
  }

  ul {
    list-style: disc;
    margin-left: 20px;
    padding-bottom: 0;
  }

  ol {
    padding-bottom: 0;
  }

  ul li,
  ol li {
    font-size: ${props => props.theme.fontSizes.nm};
    line-height: 1.75;
    padding-bottom: 0;
    padding-left: 16px;
    margin: 0 0 ${props => props.theme.margins.nm};
    &:last-of-type {
      padding-bottom: 0;
    }
  }

  blockquote {
    margin: ${props => props.theme.margins.lg} 3rem
      ${props => props.theme.margins.xl} 3rem;
    font-style: italic;
  }

  blockquote p {
    font-size: ${props => props.theme.fontSizes.md};
    font-weight: 300;
    color: ${props => props.theme.colors.text};
    padding-bottom: ${props => props.theme.margins.sm};
    line-height: 1.55;
    opacity: 0.65;
  }

  strong {
    font-weight: ${props => props.theme.weights.bold};
  }

  iframe {
    min-width: 100vw;
    max-height: 500px;
    align-self: center;
    margin-bottom: ${props => props.theme.margins.xxl};
  }

  pre {
    display: flex;
  }

  img {
    width: 100%;
  }

  code {
    padding: 0 2px;
    background: ${props => props.theme.colors.primary100};
  }

  pre {
    code {
      width: 100%;
      padding: ${props => props.theme.margins.lg};
      background: transparent;
      overflow: scroll;
      span {
        font-family: monospace !important;
      }

      line-height: 1.65;
    }
  }

  pre[class*="language-"],
  pre .language-css,
  pre .language-javascript,
  pre .language-html {
    background: ${props => props.theme.colors.primary100};
    border-radius: ${p => p.theme.radius.sm};
    margin-bottom: ${props => props.theme.margins.xl};
    max-width: calc(100vw - 2rem) !important;
    code {
      font-family: monospace !important;
    }

    @media (max-width: ${props => props.theme.screen.mobile}) {
      font-size: 10px !important;
    }
  }

  code {
    font-family: monospace !important;
  }

  iframe {
    width: 100%;
    flex: 1 0 auto;
    margin-top: ${props => props.theme.margins.lg};
    height: 1000px;
  }
  video {
    width: 100%;
  }
`

export const MarkdownTrainerStyle = styled(MarkdownStyle)`
  p {
    color: ${props => props.theme.colors.primary100};
  }
  a {
    color: ${props => props.theme.colors.primary700};
    &:hover {
      text-decoration: underline;
    }
    &:visited {
      color: ${props => props.theme.colors.primary700};
    }
  }
`

export const MarkdownPostStyle = styled(MarkdownStyle)`
  iframe {
    flex: 1 1 100%;
    width: 100% !important;
    max-width: 100% !important;
    min-width: auto !important;
  }
`

export default MarkdownStyle
