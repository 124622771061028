import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Title, Subtitle } from "../components/base/Text"
import { Background, FluidContainer } from "../components/base/Container"
import { trainersIcons } from "../components/base/Trainers"
import { MarkdownTrainer } from "../components/base/markdown/Markdown"

const Container = styled.div`
  padding: ${props => props.theme.margins.xxl} 0;
`

const TrainersContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: ${props => props.theme.margins.xxxl};
  @media (max-width: ${props => props.theme.screen.medium}) {
    flex-direction: column;
    margin-top: 60px;
  }
  @media (max-width: ${props => props.theme.screen.mobile}) {
    margin-top: 0;
  }
`

const TrainerContainer = styled.div`
  flex: 1 1 48%;
  position: relative;
  display: flex;
  &:first-of-type {
    margin-right: ${props => props.theme.margins.xl};
    margin-top: 20px;
  }
  @media (max-width: ${props => props.theme.screen.medium}) {
    flex: 1 1 100%;
    margin-right: 0;
    padding-left: 40px;
    &:first-of-type {
      margin-bottom: 80px;
      margin-right: 0;
    }
  }
  @media (max-width: ${props => props.theme.screen.mobile}) {
    flex: 1 1 100%;
    flex-direction: column;
    padding-left: 0;
    &:first-of-type {
      margin-bottom: 60px;
      margin-right: 0;
      margin-top: 0;
    }
  }
`

const TrainerProfileImageContainer = styled.div`
  position: absolute;
  top: -40px;
  left: 0;

  width: 240px;
  height: 240px;
  box-shadow: ${props => props.theme.shadows.strong};
  @media (max-width: ${props => props.theme.screen.medium}) {
  }
  @media (max-width: ${props => props.theme.screen.mobile}) {
    position: relative;
    top: auto;
    left: auto;

    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: -40px;
  }
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${props => props.theme.colors[`${props.color}600`]};
  opacity: 0.7;
  mix-blend-mode: color;
  @media (max-width: ${props => props.theme.screen.medium}) {
  }
`

const TrainerInfoContainer = styled.div`
  padding: ${props => props.theme.margins.xl} ${props => props.theme.margins.lg};
  padding-left: 240px;
  background: ${props => props.theme.colors[`${props.color}500`]};
  margin-left: ${props => props.theme.margins.xl};
  @media (max-width: ${props => props.theme.screen.medium}) {
    padding-top: ${props => props.theme.margins.xl};
    padding-left: 240px;
    margin-left: 0;
  }
  @media (max-width: ${props => props.theme.screen.mobile}) {
    width: 100%;
    padding-left: ${props => props.theme.margins.lg};
    margin-left: 0;
  }
`

const TrainerLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.margins.nm};
  @media (max-width: ${props => props.theme.screen.mobile}) {
    padding-top: 40px;
  }
`

const IconContainer = styled.span`
  display: inline-block;
  margin-right: ${props => props.theme.margins.md};
  svg {
    width: 28px;
    height: 28px;
    stroke: ${props => props.theme.colors[`${props.color}300`]};
    fill: ${props => props.theme.colors[`${props.color}500`]};
  }
`

const VideoContainer = styled.div`
  margin-top: ${props => props.theme.margins.xxl};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const VideoWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
`

const VideoPlayerSimple = styled.video`
  object-fit: cover;
  width: 100%;
  overflow: hidden;
`

const Trainers = ({ trainers, trainersVideoUrl, color }) => {
  return (
    <Background color={`${color}900`}>
      <FluidContainer>
        <Container>
          <Title size="xl" color={`${color}100`} margin="xl" black center>
            Meet Your Instructors
          </Title>

          <TrainersContainer>
            {trainers.map(trainer => {
              const {
                name,
                title,
                company,
                bio: { bio },
                icon,
                profileImage,
              } = trainer

              const TrainerIcon = trainersIcons[icon]

              return (
                <TrainerContainer key={name}>
                  <TrainerProfileImageContainer>
                    <Img fluid={profileImage.fluid} />
                    <Overlay color={color} />
                  </TrainerProfileImageContainer>
                  <TrainerInfoContainer color={color}>
                    <TrainerLine>
                      <IconContainer color={color}>
                        <TrainerIcon />
                      </IconContainer>
                      <Subtitle size="md" color="primary100" margin="sm" black>
                        {name}
                      </Subtitle>
                    </TrainerLine>
                    <Subtitle size="sm" color={`${color}200`} margin="lg">
                      {`${title} | ${company}`}
                    </Subtitle>
                    <MarkdownTrainer text={bio} />
                  </TrainerInfoContainer>
                </TrainerContainer>
              )
            })}
          </TrainersContainer>

          <VideoContainer>
            <Title size="xl" color={`${color}100`} margin="sm" black center>
              Focus and learn
            </Title>
            <Subtitle size="md" color={`${color}200`} margin="xxl" center>
              Listen to Codecamps instructors, why learning in a remote location
              is a much better choice in comparison to the regular training
            </Subtitle>
            <VideoWrapper>
              <VideoPlayerSimple
                autoPlay={false}
                loop={false}
                playsInline
                preload="metadata"
                poster="https://s3-eu-west-1.amazonaws.com/codecamps/trainers-static-frame.jpg"
                controls
              >
                <source src={trainersVideoUrl} type="video/mp4" />
              </VideoPlayerSimple>
            </VideoWrapper>
          </VideoContainer>
        </Container>
      </FluidContainer>
    </Background>
  )
}
//
export default Trainers
