import React from "react"
import styled from "styled-components"
import { Title, Subtitle } from "../base/Text"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { optionsTestimonials } from "../base/markdown"
import StudentImage from "./StudentImage"
import { Background } from "../base/Container"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.margins.xxl}
    ${props => props.theme.gutterWidth};
  @media (min-width: ${p => p.theme.screen.medium}) {
    padding: ${props => props.theme.margins.xxl}
      ${props => props.theme.outerMargin};
  }
`

const TitleContainer = styled.div`
  margin-bottom: 80px;
`

const TestimonialsContainer = styled.div`
  display: flex;
  width: 100%;
  padding-left: 20px;
  @media (max-width: ${p => p.theme.screen.medium}) {
    flex-direction: column;
    padding-left: 0;
  }
`

const Testimonial = styled.div`
  position: relative;
  flex: 1 1 calc(50% - ${props => props.theme.gutterWidth});
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  padding-left: 20px;
  &:first-of-type {
    margin-right: ${props => props.theme.outerMargin};
  }
  @media (max-width: ${p => p.theme.screen.medium}) {
    &:first-of-type {
      margin-bottom: ${props => props.theme.margins.xxxl};
      margin-right: 0;
    }
  }
`

const ImageContainer = styled.div`
  position: absolute;
  top: -40px;
  left: -20px;
  width: 240px;
  height: 240px;
  box-shadow: ${props => props.theme.shadows.strong};
  @media (max-width: ${p => p.theme.screen.medium}) {
    width: 140px;
    height: 140px;
    top: -20px;
    left: 0;
  }
`

const TestimonialTextContainer = styled.div`
  flex: 1 1 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.margins.lg};
  padding-left: 240px;
  background: ${props => props.theme.colors[`${props.color}900`]};
  @media (max-width: ${p => p.theme.screen.medium}) {
    padding-left: 140px;
  }
`

const StudentDescription = styled.div`
  margin-top: auto;
`

const Testimonials = ({ testimonials, color }) => (
  <Background color="grey300">
    <Container>
      <TitleContainer>
        <Title size="xxl" color={`${color}900`} center black>
          What our students think
        </Title>
      </TitleContainer>
      <TestimonialsContainer>
        {testimonials.map(testimonial => {
          const {
            body: { json },
            studentName,
            studentTitle,
            studentCompany,
            studentProfileImage,
          } = testimonial

          const testimonialBody = documentToReactComponents(
            json,
            optionsTestimonials
          )

          return (
            <Testimonial key={studentName}>
              <ImageContainer>
                <StudentImage fluid={studentProfileImage.fluid} color={color} />
              </ImageContainer>
              <TestimonialTextContainer color={color}>
                {testimonialBody}
                <StudentDescription>
                  <Subtitle size="nm" color={`${color}200`} bold>
                    {studentName}
                  </Subtitle>
                  <Subtitle size="sm" color={`${color}300`}>
                    {`${studentTitle}${
                      studentCompany ? `, ${studentCompany}` : ""
                    }`}
                  </Subtitle>
                </StudentDescription>
              </TestimonialTextContainer>
            </Testimonial>
          )
        })}
      </TestimonialsContainer>
    </Container>
  </Background>
)

export default Testimonials
