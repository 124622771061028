import React from "react"
import styled from "styled-components"
import { Title, Subtitle } from "../components/base/Text"
import { FluidContainer } from "../components/base/Container"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { getOptionsUsp, getOptionsBenefits } from "./formatter"

const Container = styled.div`
  display: flex;
  margin-top: -110px;
  width: 100%;
  padding: ${props => props.theme.margins.xxl} 0;
  max-width: ${props => props.theme.containers.lg};
  @media (max-width: ${p => p.theme.screen.medium}) {
    margin-top: -90px;
  }
  @media (max-width: ${p => p.theme.screen.mobile}) {
    margin-top: ${props => props.theme.margins.lg};
    padding-top: 0;
    flex-direction: column;
  }
`

const LeftContainer = styled.div`
  width: 50%;
  flex: 1 1 100%;
  flex-direction: column;
  background: white;
  box-shadow: ${props => props.theme.shadows.lg};
  margin-right: ${props => props.theme.margins.xl};
  padding: ${props => props.theme.margins.xl};
  z-index: 10000;
  @media (max-width: ${p => p.theme.screen.mobile}) {
    width: 100%;
    margin-right: 0;
    margin-bottom: ${props => props.theme.margins.md};
    padding: ${props => props.theme.margins.lg};
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
`

const RightContainer = styled(LeftContainer)`
  background: ${props => props.theme.colors[`${props.color}500`]};
  margin-right: 0;
`

const InternalWrapper = styled.div`
  flex: 1 1 100%;
`

const ButtonContainer = styled.div`
  margin-top: auto;
  width: 100%;
  padding: ${props => props.theme.margins.xl} 0 0 0;
  @media (max-width: ${props => props.theme.screen.mobile}) {
    padding: ${props => props.theme.margins.lg} 0 0 0;
    a {
      width: 100%;
    }
  }
`

const DownloadButton = styled.button`
  outline: 0;
  border: 0;
  height: 60px;
  min-width: 100%;
  color: white;
  background: ${props => props.theme.colors[`${props.color}500`]};
  padding: 0 ${props => props.theme.margins.xl};
  font-size: ${props => props.theme.fontSizes.nm};
  text-align: center;
  border-radius: ${props => props.theme.radius.sm};
  cursor: default;
  margin-right: ${props => props.theme.margins.lg};
  transition: all 0.15s ease-in-out;
  &:hover {
    background: ${props => props.theme.colors[`${props.color}400`]};
    transform: translateY(-1px);
    box-shadow: ${props => props.theme.shadows.sm};
  }
  &:active {
    background: ${props => props.theme.colors[`${props.color}300`]};
    transform: translateY(0);
    box-shadow: none;
  }
  @media (max-width: ${props => props.theme.screen.mobile}) {
    margin: 0;
  }
`

const GetTicketsButton = styled(DownloadButton)`
  background: ${props => props.theme.colors.secondary500};
  margin-right: 0;
  &:hover {
    background: ${props => props.theme.colors.secondary400};
  }
  &:active {
    background: ${props => props.theme.colors.secondary500};
  }
`

// Tiny changes

const Usp = ({ usp, benefits, checkoutUrl, color, programDownloadUrl }) => {
  return (
    <FluidContainer>
      <Container>
        <LeftContainer>
          <InternalWrapper>
            <Title size="xl" color={`${color}500`} margin="md" black>
              One-week focused training
            </Title>
            <Subtitle size="md" color="grey900" margin="md" bold>
              Learn from senior developers who work on successful projects
            </Subtitle>
            {documentToReactComponents(usp, getOptionsUsp(color))}
          </InternalWrapper>

          <ButtonContainer>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={programDownloadUrl}
              download
            >
              <DownloadButton type="text" color={color}>
                Day-by-day Program
              </DownloadButton>
            </a>
          </ButtonContainer>
        </LeftContainer>

        <RightContainer color={color}>
          <InternalWrapper>
            <Title size="xl" color="white" margin="md" black>
              Join if you are:
            </Title>
            {documentToReactComponents(benefits, getOptionsBenefits(color))}
          </InternalWrapper>

          <ButtonContainer>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://checkout.codecamps.com/${checkoutUrl}`}
            >
              <GetTicketsButton color={color}>Book Now</GetTicketsButton>
            </a>
          </ButtonContainer>
        </RightContainer>
      </Container>
    </FluidContainer>
  )
}

export default Usp
