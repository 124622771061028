import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import { FluidContainer } from "./Container"
import { Title } from "./Text"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.margins.xxl} 0;
`

const InternalContainer = styled.div`
  width: 100%;
  max-width: ${props => props.theme.containers.md};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

const LogoContainer = styled.span`
  display: inline-block;
  padding: ${props => props.theme.margins.md} ${props => props.theme.margins.lg};
  div {
    height: 40px;
  }
`

const query = graphql`
  query {
    allContentfulLogo {
      edges {
        node {
          companyName
          position
          image {
            fixed(width: 120) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
        }
      }
    }
  }
`
const Logos = () => (
  <StaticQuery
    query={query}
    render={data => {
      const logos = data.allContentfulLogo.edges
        .map(({ node }) => node)
        .sort((a, b) => a.position - b.position)

      return (
        <FluidContainer>
          <Container>
            <Title size="md" color="grey400" margin="md" black>
              Trusted by developers from
            </Title>
            <InternalContainer>
              {logos.map(logo => {
                const { companyName, image } = logo
                return (
                  <LogoContainer key={`${companyName}-logo`}>
                    <Img fixed={image.fixed} />
                  </LogoContainer>
                )
              })}
            </InternalContainer>
          </Container>
        </FluidContainer>
      )
    }}
  />
)

export default Logos
