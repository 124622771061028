import React from "react"
import styled from "styled-components"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import shortid from "shortid"
import { TickIcon, RightArrowIcon } from "../components/base/Icons"
import { Subtitle } from "../components/base/Text"
import { MarkdownTrainer } from "../components/base/markdown/Markdown"

const Options = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`

const Option = styled.li`
  display: flex;
  align-items: flex-start;
  padding: ${props => props.theme.margins.nm} 0;
  color: ${props => props.theme.colors.transparent75};
`

const IconContainer = styled.div`
  display: inline-block;
  margin-right: ${props => props.theme.margins.md};
  svg {
    width: 24px;
    height: 24px;
    stroke: ${props => props.theme.colors[`${props.color}300`]};
    /* fill: ${props => props.theme.colors.primary300}; */

    fill: transparent;
  }
`

const IconContainerLight = styled(IconContainer)`
  svg {
    stroke: ${props => props.theme.colors[`${props.color}200`]};
  }
`

const ProgramIconContainer = styled(IconContainer)`
  margin-right: ${props => props.theme.margins.nm};
  svg {
    height: 12px;
    stroke: ${props => props.theme.colors.grey700};
    transform: translateY(2px);
  }
`

export const getOptionsUsp = color => ({
  renderMark: {
    [MARKS.BOLD]: text => <em key={shortid.generate()}>{text}</em>,
  },
  renderNode: {
    [BLOCKS.UL_LIST]: (node, children) => {
      return (
        <Options>
          {children.map((child, index) => {
            const text = child.props.children[0].props.children[0]
            return (
              <Option key={`usp-${index}`}>
                <IconContainer color={color}>
                  <TickIcon />
                </IconContainer>
                <Subtitle size="nm" color="grey700">
                  {text}
                </Subtitle>
              </Option>
            )
          })}
        </Options>
      )
    },
  },
})

export const getOptionsBenefits = color => ({
  renderMark: {
    [MARKS.BOLD]: text => <em key={shortid.generate()}>{text}</em>,
  },
  renderNode: {
    [BLOCKS.UL_LIST]: (node, children) => {
      return (
        <Options>
          {children.map((child, index) => {
            const text = child.props.children[0].props.children[0]
            return (
              <Option key={`usp-${index}`}>
                <IconContainerLight color={color}>
                  <TickIcon />
                </IconContainerLight>
                <Subtitle size="nm" color="primary100">
                  {text}
                </Subtitle>
              </Option>
            )
          })}
        </Options>
      )
    },
  },
})

export const optionsProgram = {
  renderMark: {
    [MARKS.BOLD]: text => <em key={shortid.generate()}>{text}</em>,
  },
  renderNode: {
    [BLOCKS.UL_LIST]: (node, children) => {
      return (
        <Options>
          {children.map((child, index) => {
            const text = child.props.children[0].props.children[0]
            return (
              <Option key={`usp-${index}`}>
                <ProgramIconContainer>
                  <RightArrowIcon />
                </ProgramIconContainer>
                <Subtitle size="nm" color="grey900">
                  {text}
                </Subtitle>
              </Option>
            )
          })}
        </Options>
      )
    },
  },
}

export const optionsActivities = {
  renderMark: {
    [MARKS.BOLD]: text => <em key={shortid.generate()}>{text}</em>,
  },
  renderNode: {
    [BLOCKS.UL_LIST]: (node, children) => {
      return (
        <Options>
          {children.map((child, index) => {
            const text = child.props.children[0].props.children[0]
            return (
              <Option key={`usp-${index}`}>
                <Subtitle size="nm" color="grey900">
                  {text}
                </Subtitle>
              </Option>
            )
          })}
        </Options>
      )
    },
  },
}

export const optionsTrainers = {
  renderMark: {
    [MARKS.BOLD]: text => <em key={shortid.generate()}>{text}</em>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <MarkdownTrainer text={children[0]} />
    },
  },
}
