import { format, isSameMonth, isSameDay } from "date-fns"

export const getDateRange = ({ startDate, endDate }) => {
  if (isSameDay(endDate, startDate)) {
    return format(startDate, "D MMM, YYYY")
  }
  if (isSameMonth(endDate, startDate)) {
    return `${format(startDate, "D")}-${format(endDate, "D MMM, YYYY")}`
  }

  return `${format(startDate, "D MMM")}-${format(endDate, "D MMM, YYYY")}`
}
