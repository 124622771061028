import React, { Component } from "react"

import ReactMarkdown from "react-markdown"
import MarkdownStyle, {
  MarkdownTrainerStyle,
  MarkdownPostStyle,
} from "./MarkdownStyle"

class Markdown extends Component {
  render() {
    const { text, light } = this.props
    return (
      <MarkdownStyle light={light}>
        <ReactMarkdown source={text} />
      </MarkdownStyle>
    )
  }
}

export class MarkdownTrainer extends Component {
  render() {
    const { text, light } = this.props
    return (
      <MarkdownTrainerStyle light={light}>
        <ReactMarkdown source={text} />
      </MarkdownTrainerStyle>
    )
  }
}

export class MarkdownPost extends Component {
  render() {
    const { text, light } = this.props
    return (
      <MarkdownPostStyle light={light}>
        <ReactMarkdown source={text} escapeHtml={false} />
      </MarkdownPostStyle>
    )
  }
}

export default Markdown
