import React from "react"
import Helmet from "react-helmet"

const Metatags = ({ metaTitle, metaDescription, slug, cardUrl }) => {
  return (
    <Helmet>
      <title>Codecamps - Immersive Coding Bootcamps</title>
      <meta name="description" content={metaDescription} />
      <link rel="canonical" href="https://www.codecamps.com" />
      <link rel="shortcut icon" href="/favicon.png" />

      {/* Schema.org markup for Google+ */}
      <meta itemprop="name" content={metaTitle} />
      <meta itemprop="description" content={metaDescription} />
      <meta itemprop="image" content={cardUrl} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@codecamps" />
      <meta name="twitter:creator" content="@codecamps" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={cardUrl} />

      {/* Open Graph data */}
      <meta property="og:title" content={metaTitle} />
      <meta property="og:type" content="event" />
      <meta property="og:url" content={`https://www.codecamps.com/${slug}/`} />
      <meta property="og:image" content={cardUrl} />
      <meta property="og:description" content={metaDescription} />
      <meta
        property="og:site_name"
        content="Codecamps - Immersive Coding Bootcamps"
      />
    </Helmet>
  )
}

export default Metatags
