import React from "react"
import styled from "styled-components"
import { MainTitle, Subtitle } from "../components/base/Text"
import { getDateRange } from "../components/utils"
import Rating from "../components/base/Stars"

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${props => props.theme.margins.xl}
    ${props => props.theme.outerMargin};
  @media (max-width: ${p => p.theme.screen.mobile}) {
    padding: ${props => props.theme.margins.md}
      ${props => props.theme.outerMargin};
  }
`

const CourseTitle = styled(MainTitle)`
  color: ${props => props.theme.colors[props.color]};
  margin-bottom: ${props => props.theme.margins.sm};
  @media (max-width: ${p => p.theme.screen.medium}) {
    font-size: ${props => props.theme.fontSizes.xxxl};
    margin-bottom: 0;
  }
  @media (max-width: ${p => p.theme.screen.mobile}) {
    font-size: ${props => props.theme.fontSizes.xl};
  }
`

const CourseSubtitle = styled(MainTitle)`
  @media (max-width: ${p => p.theme.screen.medium}) {
    font-size: ${props => props.theme.fontSizes.xl};
  }
  @media (max-width: ${p => p.theme.screen.mobile}) {
    font-size: ${props => props.theme.fontSizes.lg};
  }
`

const CourseDate = styled(Subtitle)`
  margin-top: ${props => props.theme.margins.lg};
  margin-bottom: ${props => props.theme.margins.lg};
  @media (max-width: ${p => p.theme.screen.medium}) {
    font-size: ${props => props.theme.fontSizes.md};
    margin-top: ${props => props.theme.margins.nm};
    margin-bottom: ${props => props.theme.margins.sm};
  }
  @media (max-width: ${p => p.theme.screen.mobile}) {
    font-size: ${props => props.theme.fontSizes.nm};
    margin-top: ${props => props.theme.margins.nm};
    margin-bottom: 0;
  }
`

const Landing = ({ title, startDate, endDate, location, color }) => {
  const courseTitle = title.split(" ")[0]
  const courseSubtitle = title.substr(title.indexOf(" ") + 1)
  return (
    <Container>
      <CourseTitle size="xxxxl" color={color} lineHeight={1} black capitalize>
        {courseTitle}
      </CourseTitle>
      <CourseSubtitle size="xxxl" color="white" lineHeight={1} black capitalize>
        {courseSubtitle}
      </CourseSubtitle>
      <CourseDate size="lg" color="white" bold>
        {`${getDateRange({ startDate, endDate })}, ${location.city}, ${
          location.country
        }`}
      </CourseDate>
      <Rating />
    </Container>
  )
}

export default Landing
