import {
  DominicIcon,
  AnthonyIcon,
  VictorIcon,
  RichardIcon,
  AlexIcon,
  KwintenIcon,
} from "./Icons"

export const trainersIcons = {
  dominic: DominicIcon,
  anthony: AnthonyIcon,
  victor: VictorIcon,
  richard: RichardIcon,
  alex: AlexIcon,
  kwinten: KwintenIcon,
}
