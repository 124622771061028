import React from "react"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { Span } from "./Text"
import shortid from "shortid"

export const optionsTestimonials = {
  renderMark: {
    [MARKS.BOLD]: text => <em key={shortid.generate()}>{text}</em>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Span key={shortid.generate()} size="nm" color="primary100" margin="xl">
        {children}
      </Span>
    ),
  },
}
