import React from "react"
import styled from "styled-components"
import { Title } from "../components/base/Text"
import { Background, FluidContainer } from "../components/base/Container"

const Container = styled.div`
  padding: ${props => props.theme.margins.xxl} 0;
`

const VideoContainer = styled.div`
  display: flex;
  @media (max-width: ${props => props.theme.screen.medium}) {
    flex-direction: column;
  }
`

const VideoWrapper = styled.div`
  flex: 1 1 calc(33% - 1.333rem);
  margin-right: ${props => props.theme.outerMargin};
  &:nth-child(3n) {
    margin-right: 0;
  }
  @media (max-width: ${props => props.theme.screen.medium}) {
    margin-right: 0;
    margin-bottom: ${props => props.theme.margins.md};
  }
`

const VideoPlayerSimple = styled.video`
  object-fit: cover;
  width: 100%;
  overflow: hidden;
`

const videos = [
  {
    url:
      "https://player.vimeo.com/external/261986190.hd.mp4?s=12f4efa0980422af2960490039a1dbaf69c72818&profile_id=174",
  },
  {
    url:
      "https://player.vimeo.com/external/261982998.hd.mp4?s=d0ba211d14430362e8814d89d374fafcb8168ffb&profile_id=174",
  },
  {
    url:
      "https://player.vimeo.com/external/261989243.hd.mp4?s=0aaeabbe45d4f3500cc000a6f5a3187f5341cc35&profile_id=174",
  },
]

const Students = ({ color }) => (
  <Background color="white">
    <FluidContainer>
      <Container>
        <Title size="xl" color={`${color}900`} margin="xl" black center>
          Find out why developers love this training...
        </Title>

        <VideoContainer>
          {videos.map((video, index) => (
            <VideoWrapper key={`student-video-${index}`}>
              <VideoPlayerSimple
                autoPlay={false}
                loop={false}
                playsInline
                preload="metadata"
                // poster={trainersStaticFrame}
                controls
              >
                <source src={video.url} type="video/mp4" />
              </VideoPlayerSimple>
            </VideoWrapper>
          ))}
        </VideoContainer>
      </Container>
    </FluidContainer>
  </Background>
)

export default Students
