import React from "react"
import styled from "styled-components"
import { StarIcon } from "./Icons"
import { Span } from "./Text"

const RatingContainer = styled.span`
  display: flex;
  align-items: center;

  @media (max-width: ${props => props.theme.screen.mobile}) {
    margin-top: ${props => props.theme.margins.sm};
  }
`

const IconContainer = styled.div`
  display: flex;
  padding-right: ${props => props.theme.margins.sm};
  svg {
    width: 24px;
    height: 24px;
    stroke: ${props => props.theme.colors.instagram};
    fill: transparent;
  }
  @media (max-width: ${props => props.theme.screen.medium}) {
    svg {
      width: 16px;
      height: 16px;
    }
  }
  @media (max-width: ${props => props.theme.screen.mobile}) {
    svg {
      width: 12px;
      height: 12px;
    }
  }
  &:last-of-type {
    margin-right: ${props => props.theme.margins.nm};
  }
`

const ReviewsText = styled(Span)`
  @media (max-width: ${props => props.theme.screen.mobile}) {
    font-size: ${props => props.theme.fontSizes.xs};
  }
`

const Rating = () => (
  <a
    href="https://www.facebook.com/codecampshq/reviews/"
    target="_blank"
    rel="noopener noreferrer"
  >
    <RatingContainer>
      {[1, 1, 1, 1, 1].map((star, index) => (
        <IconContainer key={`star-icon-${index}`}>
          <StarIcon />
        </IconContainer>
      ))}
      <ReviewsText size="sm" color="transparent75">
        Reviews (15)
      </ReviewsText>
    </RatingContainer>
  </a>
)

export default Rating
