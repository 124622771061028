import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { BackgroundImageContainer } from "../course/Components"
import Landing from "../course/Landing"
import Usp from "../course/Usp"
import Testimonials from "../components/testimonials/Testimonials"
import Program from "../course/Program"
import Trainers from "../course/Trainers"
import Students from "../course/Students"
import FAQs from "../course/FAQs"
import Metatags from "../components/base/Metatags"
import Logos from "../components/base/Logos"

const CourseTemplate = ({ data }) => {
  const {
    title,
    slug,
    courseBackground,
    location,
    startDate,
    endDate,
    testimonials,
    brochureUrl,
    beneficiary: { json: benefits },
    usp: { json: usp },
    program,
    color,
    trainers,
    checkoutUrl,
    trainersVideoUrl,
    metaTitle,
    metaDescription,
    cardUrl,
    programDownloadUrl,
    poweredBy,
    poweredByLogo,
    poweredByUrl,
  } = data.contentfulCourse

  return (
    <Layout>
      <Metatags
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        slug={slug}
        cardUrl={cardUrl}
      />
      <BackgroundImageContainer>
        <Img
          fluid={courseBackground.fluid}
          title={courseBackground.description}
          critical
        />
        <Landing
          title={title}
          location={location}
          startDate={startDate}
          endDate={endDate}
          color={color}
        />
      </BackgroundImageContainer>

      <Usp
        usp={usp}
        benefits={benefits}
        brochureUrl={brochureUrl}
        checkoutUrl={checkoutUrl}
        color={color}
        programDownloadUrl={programDownloadUrl}
      />

      <Logos />

      <Testimonials testimonials={testimonials} color={color} />
      <Program
        program={program}
        color={color}
        poweredBy={poweredBy}
        poweredByLogo={poweredByLogo}
        poweredByUrl={poweredByUrl}
      />

      <Trainers
        trainers={trainers}
        trainersVideoUrl={trainersVideoUrl}
        color={color}
      />
      <Students color={color} />
      <FAQs color={color} />
    </Layout>
  )
}

export default CourseTemplate

export const query = graphql`
  query($slug: String!) {
    contentfulCourse(slug: { eq: $slug }) {
      title
      slug
      startDate
      endDate
      isBookingOpen
      isPastCourse
      price
      earlyBirdPrice
      earlyBirdEndDate
      videoUrl
      color
      brochureUrl
      checkoutUrl
      trainersVideoUrl
      metaTitle
      metaDescription
      cardUrl
      programDownloadUrl
      poweredBy
      poweredByLogo {
        fluid(maxWidth: 120) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      poweredByUrl
      description {
        description
      }
      prerequisites {
        prerequisites
      }
      beneficiary {
        beneficiary
      }

      trainers {
        name
        title
        company
        bio {
          bio
        }
        twitter
        profileImage {
          title
          description
          fluid(maxWidth: 400) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
        icon
      }
      frameworkLogo {
        title
        description
        fluid(maxWidth: 400) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      courseLogo {
        title
        description
        fluid(maxWidth: 400) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      courseBackground {
        title
        description
        fluid(maxWidth: 1440) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      location {
        city
        country
        description {
          description
        }
      }
      coworking {
        title
        description {
          description
        }
        photos {
          title
          description
          fluid(maxWidth: 2880) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      program {
        title
        blocks {
          title
          topics {
            json
          }
          activities {
            json
          }
          temperature
          weather
          trainingRatio
          surfingRatio
          sightseeingRatio
          tripRatio
          drinksRatio
          yogaRatio
        }
      }
      usp {
        json
      }
      beneficiary {
        json
      }
      testimonials {
        body {
          json
        }
        id
        studentName
        studentTitle
        studentCompany
        studentProfileImage {
          fluid(maxWidth: 400) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`
